<template>
  <div class="box-body">
    <transition name="fade">
      <div v-if="[1, 2, 7, 8, 9].includes(this.$store.getters['auth/authUser'].role.id) && !onProgress"
           class="form-group">
        <input type="radio" class="btn-check" name="options-outlined" id="show_only-my"
               @input="() => {
                 filter_tasks.show_only = 'my';
                 getTasks();
               }" autocomplete="off" :checked="filter_tasks.show_only === 'my'">
        <label class="btn btn-outline-primary" for="show_only-my">Мои</label>

        <input type="radio" class="btn-check" name="options-outlined" id="show_only-all"
               @input="() => {
                 filter_tasks.show_only = 'all';
                 getTasks();
               }" autocomplete="off" :checked="filter_tasks.show_only === 'all'">
        <label class="btn btn-outline-primary" for="show_only-all">Все</label>
      </div>
    </transition>

    <div v-if="show_task_type_statuses && task_type_statuses.length && !onProgress" class="mt-2 mb-2">
      <span v-for="task_type_status in task_type_statuses"
            class="label label-default text-white font-medium pointer"
            v-on:click="() => {
                if (filter_tasks.type_status_id !== task_type_status.id) {
                  filter_tasks.type_status_id = task_type_status.id;
                } else {
                  filter_tasks.type_status_id = null;
                }
                getTasks();
              }"
            :style="'padding: 2px 6px; font-size: 14px; background:' + task_type_status.custom_color.replace('0.25', '1')">
          {{ task_type_status.name }}
        </span>
    </div>

    <transition name="fade">
      <div class="form-group">
        <div class="row filters-block">
          <label class="col-xl-4 col-md-6 col-sm-12 mb-1" for="filter-by-branch">
            <multiselect :allow-empty="true"
                         :options="branches"
                         :searchable="false"
                         :show-labels="true"
                         id="filter-by-branch"
                         @input="() => {
                           filter_tasks.branch_id = branch ? branch.id : null;
                           getEmployees(filter_tasks.branch_id);
                           getTasks();
                         }"
                         deselect-label="Убрать"
                         label="name"
                         placeholder="Филиал"
                         required
                         select-label="Выбрать"
                         selected-label="Выбрано"
                         title="Филиал"
                         track-by="id"
                         v-model="branch">
              <template slot="noResult">Не найдено</template>
              <template slot="noOptions">Данные отсутствуют</template>
              <template slot="singleLabel" slot-scope="props">
                {{ props.option.title }}, {{ props.option.address }}
              </template>
              <template slot="option" slot-scope="props">
                {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
              </template>
            </multiselect>
          </label>

          <label v-if="filter_tasks.show_only === 'all' && employees.length"
                 class="col-xl-4 col-md-6 col-sm-12 mb-1" for="filter-by-responsible">
            <multiselect :allow-empty="true"
                         :options="employees"
                         :searchable="true"
                         id="filter-by-responsible"
                         @input="() => {
                           filter_tasks.responsible_id = responsible ? responsible.id : null;
                           getTasks();
                         }"
                         deselect-label="Убрать"
                         label="title"
                         placeholder="Ответственный"
                         required
                         select-label="Выбрать"
                         selected-label="Выбрано"
                         title="Ответственный"
                         track-by="id"
                         v-model="responsible">
              <template slot="noResult">Не найдено</template>
              <template slot="noOptions">Данные отсутствуют</template>
            </multiselect>
          </label>
        </div>
      </div>
    </transition>

    <div class="table-responsive" v-if="task_type_states.length && !onProgress">
      <table class="table table-condensed table-bordered">
        <thead>
        <tr>
          <th v-for="tasks_state in task_type_states"
              :style="'background:' + tasks_state.custom_color.replace('0.25', '0.5')">
            {{ tasks_state.name }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td v-for="task_state in task_type_states"
              v-bind:style="'width: ' + (column_width ? (column_width + '%') : 'auto')">
            <funnel-card-items :filters_query="filter_tasks"
                               :relations="relations_tasks"
                               :type_id="task_state.id"
                               component="funnel-task-item"
                               :api_resource="'/tasks/type/' + task_type_id + '/state/' + task_state.id + '/items'" />
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <loading-processing v-else></loading-processing>

    <div class="panel panel-default" v-if="task_type_states.length === 0 && !onProgress">
      <div class="panel-body text-center text-muted mt-5 mb-5">
        Нет элементов для отображения
      </div>
    </div>

    <modal :adaptive="true"
           :clickToClose="true"
           :draggable="false"
           :name="'create_quick_task_modal'"
           :resizable="false"
           :scrollable="false"
           :shiftX="0.5"
           :shiftY="0.5"
           :height="'auto'"
           overlayTransition="fade"
           transition="fade"
           @closed="hideCreateQuickTaskModal">
      <div class="box box-primary box-modal mb-0">
        <div class="box-header with-border mb-3">
          <h4>Создать задачу</h4>
        </div>

        <div class="box-body">
          <create-quick-task-form :tasks_type_id="task_type_id"
                                  :is_personal_task="task_type_id === 101 ? true : null"
                                  :is_branch_task="task_type_id === 102 ? true : null" />
        </div>
      </div>
    </modal>

    <modal v-if="currentTask && task_type_states.length && !onProgress"
           :adaptive="true"
           :clickToClose="true"
           :draggable="false"
           :name="'change_task_modal_' + currentTask.id"
           :resizable="false"
           :scrollable="false"
           :shiftX="0.5"
           :shiftY="0.5"
           :height="'auto'"
           overlayTransition="fade"
           transition="fade"
           @closed="hideChangeTaskModal">
      <div class="box box-primary box-modal mb-0">
        <div class="box-header with-border mb-3">
          <h4>#{{ currentTask.id }}</h4>
        </div>

        <div class="box-body mb-3">
          <edit-task-state-personal-form
            :task="currentTask"
            :is_personal_task="task_type_id === 101 ? true : null"/>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from "vue";
import FunnelCardItems from "./../../../components/pagination/funnel-card-items";
import BranchService from "@/services/BranchService";
import Multiselect from "vue-multiselect";
import VModal from "vue-js-modal";
import TaskTypeStatusesService from "@/services/TaskTypeStatusesService";

Vue.use(VModal);

Vue.component("funnel-task-item", (resolve) => {
  return require(["./item"], resolve);
});

Vue.component("create-quick-task-form", (resolve) => {
  return require(["./create-quick-form"], resolve);
});

Vue.component("edit-task-state-personal-form", (resolve) => {
  return require(["./../details/edit-state-personal-form"], resolve);
});

export default {
  name: "funnel-tasks",

  components: {
    FunnelCardItems,
    Multiselect,
  },

  props: {
    task_type_id: {
      type: Number,
      required: true,
    },
    show_task_type_statuses: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data: () => ({
    task_type_states: [],
    tasks: [],
    tasks_statistic: null,

    task_type_statuses: [],
    task_type_status: null,
    branches: [],
    branch: null,
    employees: [],
    responsible: null,

    currentTask: null,

    filter_tasks: {
      type_id: null,
      only_with_state: true,
      with_basic_status: true,
      show_only: null,
      type_status_id: null,
      branch_id: null,
      responsible_id: null,
    },
    relations_tasks: [
      'car.sold',
      'client',
      "type.states",
      "type_state",
      "type_status",
      "responsible.branch",
      "creator",
      "branch",
      "department",
      "last_comment",
    ],

    column_width: null,

    onProgress: true,
  }),

  async created() {
    try {
      this.filter_tasks.type_id = this.task_type_id;

      this.EventBus.$on("task-items-reload", this.getTasks);
      this.EventBus.$on("tasks-statistic-reload", this.getTasksStatistic);

      this.EventBus.$on("scroll-card-items", target => {
        this.onScroll(target);
      });

      this.EventBus.$on("open-create-quick-task-modal", () => {
        this.showCreateQuickTaskModal();
      });

      this.EventBus.$on("hide-quick-create-task-modal", () => {
        this.hideCreateQuickTaskModal();
      });
      this.EventBus.$on("open-edit-task-modal", data => {
        this.currentTask = data.task;
        this.showChangeTaskModal();
      });
      this.EventBus.$on("hide-edit-task-modal", () => {
        this.hideChangeTaskModal();
      });

      if ([1, 2, 7, 8, 9].includes(this.$store.getters["auth/authUser"].role.id)) {
        this.filter_tasks.show_only = "all";
      } else {
        this.filter_tasks.show_only = "my";
      }

      const [
        task_type_states_response,
        tasks_statistic_response,
        task_type_statuses_response,
        branches_response,
        employees_response,
      ] = await axios.all([
        API.apiClient.get(
          "/tasks/type/" + this.task_type_id + "/states",
        ),
        API.apiClient.get("/tasks/statistic/count"),
        this.show_task_type_statuses ? TaskTypeStatusesService.getItems(true) : null,
        BranchService.getItems(),
        [1, 2, 7, 8, 9].includes(this.$store.getters["auth/authUser"].role.id) ?
          API.apiClient.get("/employees", {
            params: this.$apiRequestParams.filters_list,
          }) :
          null,
      ]);

      this.task_type_states = task_type_states_response.data;
      this.tasks_statistic = tasks_statistic_response.data;
      this.task_type_statuses = task_type_statuses_response;
      this.branches = branches_response;
      this.employees = employees_response ? employees_response.data : null;

      this.EventBus.$emit("set-tasks-statistic", this.tasks_statistic);

      this.column_width = 100 / this.task_type_states.length;

      // if (localStorage.getItem("filter_tasks_" + this.task_type_id)) {
      //   this.filter_tasks = JSON.parse(localStorage.getItem("filter_tasks_" + this.task_type_id));
      //   if (this.show_task_type_statuses && this.filter_tasks.type_status_id) {
      //     this.task_type_status = this.task_type_statuses.find((task_type_status) => {
      //       return task_type_status.id === this.filter_tasks.type_status_id;
      //     });
      //   }
      //   if (this.filter_tasks.branch_id) {
      //     this.branch = this.branches.find((branch) => {
      //       return branch.id === this.filter_tasks.branch_id;
      //     });
      //   }
      //   if (this.filter_tasks.responsible_id) {
      //     this.responsible = this.employees.find((employee) => {
      //       return employee.id === this.filter_tasks.responsible_id;
      //     });
      //   }
      // }
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  methods: {
    onScroll(target) {
      if ((target.scrollTop + target.clientHeight) >= (target.scrollHeight - 1)) {
        this.task_type_states.forEach((task_type_state) => {
          this.EventBus.$emit("funnel-card-load-more-" + task_type_state.id);
        });
      }
    },

    async getTasks() {
      try {
        this.onProgress = true;

        // localStorage.setItem("filter_tasks_" + this.task_type_id, JSON.stringify(this.filter_tasks));

        this.task_type_states.forEach((task_type_state) => {
          this.EventBus.$emit("funnel-card-filter-changed-" + task_type_state.id);
        });
      } catch (error) {
        errorHandler(error);
      }
      this.onProgress = false;
    },

    async getTasksStatistic() {
      try {
        const tasks_statistic_response = await API.apiClient.get("/tasks/statistic/count");

        this.tasks_statistic = tasks_statistic_response.data;

        this.EventBus.$emit("set-tasks-statistic", this.tasks_statistic);
      } catch (error) {
        errorHandler(error);
      }
    },

    async getTaskTypeStatuses() {
      try {
        if (this.show_task_type_statuses) {
          const task_type_statuses_response = await API.apiClient.get("/tasks/types/statuses", {
            params: {
              filters_query: {
                is_flow: true,
              },
            },
          });

          this.task_type_statuses = task_type_statuses_response.data;
        }
      } catch (error) {
        errorHandler(error);
      }
    },

    async getEmployees(branchID = null) {
      try {
        if ([1, 2, 7, 8, 9].includes(this.$store.getters["auth/authUser"].role.id)) {
          const employees_response = await API.apiClient.get("/employees", {
            params: {
              filters_query: {
                is_paginated: 0,
                branch_id: branchID,
              },
            },
          });

          this.employees = employees_response.data;
        }
      } catch (error) {
        errorHandler(error);
      }
    },

    showCreateQuickTaskModal() {
      this.$modal.show("create_quick_task_modal");
    },
    hideCreateQuickTaskModal() {
      this.$modal.hide("create_quick_task_modal");
    },

    showChangeTaskModal() {
      setTimeout(() => {
        this.$modal.show("change_task_modal_" + this.currentTask.id);
      }, 100);
    },
    hideChangeTaskModal() {
      this.$modal.hide("change_task_modal_" + this.currentTask.id);
      this.currentTask = null;
    },
  },
};
</script>

<style>
.filters-block .multiselect__content-wrapper {
  position: absolute;
}
</style>

<style scoped>
tr > td {
  padding: 10px;
}

tr:hover {
  background: unset;
}

td:hover {
  background: unset;
}

th {
  border-bottom: 0 !important;
}

tr > td {
  padding: 0 !important;
}
</style>
