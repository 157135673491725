import Cookies from "js-cookie";

export default {
    async getItems(is_flow = null, is_basic = null) {
        let task_type_statuses = JSON.parse(
            Cookies.get("task_type_statuses") ?? null
        );

        if (!task_type_statuses) {
            task_type_statuses = await this.loadItems();
        }

        if (is_flow || is_basic) {
            task_type_statuses = task_type_statuses.filter(
                (task_type_status) => {
                    if (is_flow) {
                        return task_type_status.is_flow === 1;
                    }
                    if (is_basic) {
                        return task_type_status.is_basic === 1;
                    }
                }
            );
        }

        return task_type_statuses;
    },
    async loadItems() {
        try {
            const task_type_statuses_response = await API.apiClient.get(
                "/tasks/types/statuses"
            );

            if (
                task_type_statuses_response &&
                task_type_statuses_response instanceof Object &&
                task_type_statuses_response.data
            ) {
                Cookies.set(
                    "task_type_statuses",
                    JSON.stringify(task_type_statuses_response.data),
                    {
                        expires: 5,
                    }
                );

                return task_type_statuses_response.data;
            } else {
                return null;
            }
        } catch (error) {
            errorHandler(error);
        }
    },
};
