<template>
  <div>
    <div class="box text-center font-small p-1" v-if="shouldEmpty && onProgress === false">
      Нет элементов
    </div>

    <transition-group name="fade" tag="div">
      <component v-if="!shouldEmpty || onProgress === false"
                 :is="component"
                 :key="item.id"
                 v-bind:$_PaginationObject="item"
                 v-for="item in items">
      </component>
    </transition-group>

    <transition name="fade" v-if="!shouldEmpty && onProgress !== false">
      <loading-processing :size="40"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'funnel-card-items',

  props: {
    api_resource: {
      type: String,
      required: true,
    },
    type_id: {
      type: Number,
      required: true,
    },
    component: {
      type: String,
      required: true,
    },
    relations: {
      type: Array,
      required: true,
    },
    relations_count: {
      type: Array,
      required: false,
      default: null,
    },
    filters_query: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    items: [],
    url: null,
    total_items: null,
    paginate: {
      path: null,
      per_page: null,
      next_page_url: null,
    },
    items_per_page: 6,
    is_paginated: api_request_params.filters_paginate.is_paginated,
    onProgress: true,
  }),

  created() {
    this.boot();
  },

  computed: {
    shouldPaginate() {
      return this.paginate.next_page_url;
    },
    shouldEmpty() {
      return this.items && this.items.length === 0 && this.onProgress === false;
    },
    hasNextPage() {
      return this.paginate.next_page_url !== null;
    },
  },

  methods: {
    boot() {
      this.onProgress = true;
      this.url = `${this.api_resource}`;

      this.load();
    },
    async refreshItems() {
      this.items = [];

      this.paginate.path = false;
      this.paginate.per_page = false;
      this.paginate.next_page_url = false;

      await this.load();
    },
    async load() {
      try {
        this.EventBus.$off('funnel-card-filter-changed-' + this.type_id);
        this.EventBus.$off('funnel-card-resource-pagination-changed');

        this.onProgress = true;

        if (!this.response) {
          const response = await API.apiClient.get(window.handleURL(this.url), {
            params: {
              relations: this.relations,
              relations_count: this.relations_count,
              filters_query: this.filters_query,
              is_paginated: this.is_paginated,
              items_per_page: this.items_per_page,
              sorting: 'desc',
              sort_column: 'updated_at',
            }
          });

          await this.handleResponse(response);
        } else {
          await this.handleResponse(this.response);
        }

      } catch (error) {
        errorHandler(error);
      }

      this.onProgress = false;
      this.listen();
    },
    async handleResponse({data}) {
      this.items = [...this.items, ...data.data];

      this.paginate = {
        next_page_url: data.next_page_url,
        path: data.path,
        per_page: data.per_page,
      };

      if (data.next_page_url) {
        const responseUrl = new URL(data.next_page_url ? data.next_page_url : null);

        this.total_items = parseInt(responseUrl.searchParams.get('total'));
      } else {
        this.total_items = 0;
      }
    },
    async next() {
      if (this.shouldPaginate && this.hasNextPage && this.onProgress === false) {
        this.url = this.paginate.next_page_url;
        await this.load();
      }
    },
    listen() {
      this.$on('funnel-card-refresh-pagination', this.load);
      this.EventBus.$on('funnel-card-filter-changed-' + this.type_id, this.refreshItems);
      this.EventBus.$on('funnel-card-load-more-' + this.type_id, this.next);
    },
  }
}
</script>

<style scoped>

</style>
